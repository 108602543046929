<template>
    <Setup-Step
        @next="f => f()"
        :canNavigate="false"
        hideSkip
        hideYesNo
        :loadingMsg="loadingMsg"
        :settings="settings"
        showNext
        :stage.sync="stage"
        step="courier-order-plan"
        title="Courier Order Planning">
        <template v-slot:body>
            <BT-Courier-Cycle-Header showTrimmings highlightRoute title="Courier Pipeline: Plan Consignment Route" />

            <v-divider class="my-4" />

            <Setup-Block>
                <BT-Entity
                    ignoreID
                    inline
                    navigation="courier-settings"
                    single>
                    <template v-slot="{ item, save }">
                        <v-subheader>Plan Route</v-subheader>

                        <p>
                            Once a courier order is approved, you can have it automatically plan a route for the consignment.  
                            This can be set globally for every client or individually for each client.
                        </p>

                        <v-row>
                            <v-col cols="8">
                                <BT-Square-Check
                                    @change="save"
                                    falseClass="success--text"
                                    falseIcon="mdi-account-circle-outline"
                                    falseValue="Agreements"
                                    isEditing
                                    :label="item.routePlanningGuide == 'Settings' ? 'Apply To Every Client' : 'Different For Each Client'"
                                    outlined
                                    trueIcon="mdi-earth"
                                    trueValue="Settings"
                                    v-model="item.routePlanningGuide" />
                            </v-col>
                            <v-col cols="4" class="align-self-center">
                                <v-slide-x-transition hide-on-leave>
                                    <BT-Square-Check
                                        v-if="item.routePlanningGuide == 'Settings'"
                                        @change="save"
                                        falseClass="success--text"
                                        falseIcon="mdi-boom-gate"
                                        falseValue="Manual"
                                        isEditing
                                        :label="item.routePlanningTrigger"
                                        outlined
                                        trueIcon="mdi-boom-gate-up"
                                        trueValue="Auto"
                                        v-model="item.routePlanningTrigger" />
                                    <p v-else>
                                        (Define this when you add new clients)
                                    </p>
                                </v-slide-x-transition>
                            </v-col>
                        </v-row>

                        <div v-if="settings.data.courieringForSelf">
                            <v-divider class="my-4" />

                            <v-subheader>Self-Couriering</v-subheader>

                            <v-row>
                                <!-- <v-spacer /> -->
                                <v-col cols="4" class="align-self-center">
                                    <v-slide-x-transition hide-on-leave>
                                        <BT-Square-Check
                                            @change="save"
                                            falseClass="success--text"
                                            falseIcon="mdi-boom-gate"
                                            falseValue="Manual"
                                            isEditing
                                            :label="item.routePlanningTrigger"
                                            outlined
                                            trueIcon="mdi-boom-gate-up"
                                            trueValue="Auto"
                                            v-model="item.routePlanningTrigger" />
                                    </v-slide-x-transition>
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                </BT-Entity>

            </Setup-Block>
            
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Courier-Order-Plan-Setup',
    components: {
        BTCourierCycleHeader: () => import('~components/BT-Courier-Cycle-Header.vue'),
        SetupBlock: () => import('~home/setup/Setup-Block.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            loadingMsg: null,
            newItem: {},
            refreshList: false,
            stage: 0
        }
    },
    props: {
        settings: {
            type: Object,
            default: null
        },
    }
}
</script>